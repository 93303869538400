<template>
    <div class="MyDate">
        <van-field v-model="inputValue" clickable readonly v-bind="$attrs" @click-input="showPicker = true">
            <van-icon v-if="!$attrs.disabled && $attrs.clearable && inputValue"
                      slot="right-icon"
                      name="clear"
                      @click="onClear"
            />
        </van-field>
        <van-popup v-model="showPicker" position="bottom" round>
            <van-datetime-picker :type="type" :value="value" @cancel="showPicker = false" @confirm="onConfirm" />
        </van-popup>
    </div>
</template>

<script>
    import {isEmpty, timeFormat} from "@/utils/tools";

    export default {
        name: "MyDate",
        inheritAttrs: false,
        props: {
            value: [String, Number],
            type: {
                type: String,
                default: 'date'
            }
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        data() {
            return {
                showPicker: false,
                inputValue: ''
            }
        },
        watch: {
            value: {
                handler: 'setDefault'
            }
        },
        mounted() {
            this.setDefault();
        },
        methods: {
            onClear() {
                this.inputValue = '';
                this.$emit('input', '');
            },
            // 时间格式化
            dateFormat(value) {
                // 区分类型
                let fmt = this.type === 'date' ? 'yy-MM-dd' : 'yy-MM-dd hh:mm:ss';
                return timeFormat(value, fmt);
            },
            // 默认值回显
            setDefault() {
                if (!isEmpty(this.value)) {
                    // 转为时间格式
                    this.inputValue = this.dateFormat(this.value);
                } else {
                    this.inputValue = '';
                }
            },
            // 确认选择
            onConfirm(time) {
                this.inputValue = this.dateFormat(time.getTime());
                this.$emit('input', time.getTime());
                this.showPicker = false;
            },
        }
    }
</script>

<style lang="scss" scoped>
    .van-field ::v-deep .van-cell__right-icon {
        line-height: 20px;
        height: 20px;
    }
</style>
